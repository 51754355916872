<template>
	<v-fade-transition hide-on-leave>
		<template v-if="loading || loadingParent">
			<v-skeleton-loader key="transitionCard" type="card" class="rounded-xl" />
		</template>
		<template v-else>
			<v-card key="transitionEnded" class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
				<v-card-title>
					<span>{{ $t('courses.description') }}</span>
				</v-card-title>
				<v-card-text>
					<template v-if="user.info.description.length">
						<p class="card-text text-justify mx-4" style="white-space: pre-line">
							{{ user.info.description }}
						</p>
					</template>

					<template v-else>
						<div key="noItemsData" class="mx-auto">
							<div class="mt-2 mb-5 text-center">
								<v-icon size="128" role="img">
									mdi-package-variant
								</v-icon>
								<br />
								<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
							</div>
						</div>
					</template>
				</v-card-text>
			</v-card>
		</template>
	</v-fade-transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'UserDescription',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	computed: {
		...mapGetters({
			user: 'users/user'
		})
	}
}
</script>
